svg.tooth {
    cursor: pointer;
    fill: white;
  }
  
  svg.tooth .to-do {
    fill: rgb(240, 82, 82);
  }
  
  svg.tooth .done {
    fill: rgb(93, 198, 33);
  }
  
  svg.tooth .active {
    fill: gray;
  }
  
  svg.tooth polygon {
    stroke: black;
    strokeWidth: 0.5;
  }
  
  polygon:hover {
    fill: lightgray;
  }
  
  rect:hover {
    fill: lightgray;
  }
  
  .tooth-wrapper {
    margin: 0 2px;
    position: relative;
  }
  
  .flex-container-justify {
    display: flex;
    justify-content: space-around;
  }
  
  .flex-container-right {
    display: flex;
    justify-content: flex-end;
  }
  
  .flex-container-left {
    display: flex;
    justify-content: flex-start;
  }
  
  .tooth {
    font-size: 6pt;
    font-weight:normal;
    margin: 5px;
    padding: 5px;
  }