/* React Calendario style overrides */
.react-datepicker__month-container{
background-color: #ffffff !important;
}

/* Override calendars edit buttons */
.css-nl6has .MuiButton-root.cancel{
  color: #ffffff !important;
}
.css-nl6has .MuiButton-root.delete{
  color: #959595 !important;
}

.MuiTypography-root .MuiTypography-body1 .rs__hover__op .css-1gqyz35-MuiTypography-root{
  background-color: red !important;
}
