/* body {
    margin: 0;
    font-family: "Euclid Circular A", "Poppins";
    color: #ddfdfd;
    background: #4104f8;
  } */
/*   
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    gap: 50px;
    padding: 0 80px;
    text-align: center;
    height: 100vh;
    background: #050808;
  } */
  
  
  /* @media (width >= 420px) {
    .banner > img {
      width: 40vw;
    }
  } */
  
  /* @media (width >= 648px) {
    .banner {
      text-align: left;
      flex-direction: row;
      justify-content: space-between;
    }
  
    .banner > img {
      width: 10vw;
      left: 100px;
    }
  } */
  
  .waves > use {
    animation: move-forever 6s -2s linear infinite;
  }
  
  .waves > use:nth-child(2) {
    animation-delay: -8s;
    animation-duration: 15s;
  }
  .waves > use:nth-child(3) {
    animation-delay: -12s;
    animation-duration: 15s;
  }
  
  @keyframes move-forever {
    0% {
      transform: translate(-90px, 0%);
    }
    100% {
      transform: translate(85px, 0%);
    }
  }
  
  /* svg {
    position: absolute;
    left: 0;
    bottom: 200px;
    width: 100%;
    height: 20vw;
    max-height: 200px;
  } */