.sigPad {
  width: 100%;
  height: 100%;
  background-color: rgb(239, 239, 239);
}

.contractInput {
  display: flex;
  align-items: center;
  /* -webkit-appearance: none; */
  width: 5vw;
}
