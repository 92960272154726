.chat-button {
  padding: 2.5px 5px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  background-color: #aebdce; 
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.chat-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.dots-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: white;
  animation: pulse 1s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}

.thinking-container {
  display: inline-flex;
  align-items: center;
}

.thinking-icon {
  width: 12px;
  height: 12px;
  border: 2px solid white; 
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.replying-container {
  display: inline-flex;
  align-items: center;
}

.replying-icon {
  width: 12px;
  height: 12px;
  border: 2px solid white; 
  border-radius: 50%;
  border-top-color: transparent; 
  animation: spin 0.8s linear infinite;
  margin-left: 5px; 
}
